import $http from '@/utitls/request'
/**
 * 分页获取企业客户信息
 */
export const GetEnterpriseCustomPageList = (data) => {
    return $http.get('/api/EnterpriseCustom/GetEnterpriseCustomPageList', { params: data })
}
/**
 * 新增
 */
export const AddEnterpriseCustom = (data) => {
    return $http.post('/api/EnterpriseCustom/AddEnterpriseCustom', data)
}
/**
 * 修改
 */
export const EditEnterpriseCustom = (data) => {
    return $http.put('/api/EnterpriseCustom/EditEnterpriseCustom', data)
}
/**
 * 删除
 */
export const DeleteEnterpriseCustom = (data) => {
    return $http.delete('/api/EnterpriseCustom/DeleteEnterpriseCustom', { params: data })
}